export interface Term {
  slug?: string
  name: string
}

export interface Thumbnail {
  height: number
  width: number
  src: string
  srcset: string
  sizes: string
}

export interface Author {
  name: string
  email: string
  avatar: {
    url: string
  }
}

export enum BlockType {
  DELETE = 'DELETE',
  Author = 'Author',
  APALivestream = 'APALivestream',
  DCXImage = 'DCXImage',
  HtmlBlock = 'HtmlBlock',
  GdprBlock = 'GdprBlock',
}

export interface BlockAttribute {
  key: string
  value: string | undefined | null
}

export interface Block {
  t: string | BlockType
  ot?: string | undefined
  h?: string
  a?: BlockAttribute[]
  data?: {
    node: Author
  }
  provider?: GranularConsent
  previousType?: string | BlockType
}

export interface Post {
  type?: 'post' | 'apa-ticker'
  id?: string
  postId: number
  title: string
  excerpt?: string | null
  blocks: Block[]
  thumbnail?: Thumbnail
  tags?: {
    nodes?: Term[]
  }
  categories?: {
    nodes?: Term[]
  }
  author?: {
    node?: Author
  }
  dateGmt: string
  modifiedGmt?: string
  renderComponent: string
  relevantCategories?: Term[]
  link?: string
  permalink?: string
}

export interface Embed {
  block: string[]
  script?: string
  requirements?: string[]
  reload?: boolean | undefined
  id?: string
  onSuccess?: (block: Block | undefined) => void
  match?: (block: Block | undefined) => boolean
  matchedBlock?: Block | undefined
  setup?: (block: Block | undefined) => void
  appendTo?: () => HTMLElement | null
  provider: GranularConsent
}

export enum GranularConsent {
  apa = 'apa',
  apalvblg = 'apalvblg',
  apavs = 'apavs',
  brightcove = 'brightcove',
  datawrapper = 'datawrapper',
  dpavs = 'dpavs',
  elfs = 'elfs',
  facebook = 'facebook',
  fileinbox = 'fileinbox',
  glomex = 'glomex',
  iframely = 'iframely',
  infogram = 'infogram',
  instagram = 'instagram',
  lvblg = 'lvblg',
  opinionstage = 'opinionstage',
  outbrain = 'outbrain',
  pdg = 'pdg',
  pinpoll = 'pinpoll',
  player = 'player',
  spotify = 'spotify',
  strmp = 'strmp',
  tckr = 'tckr',
  tickaroo = 'tickaroo',
  tiktok = 'tiktok',
  twitter = 'twitter',
  vi = 'vi',
  vimeo = 'vimeo',
  youtube = 'youtube',
  rotblau = 'rotblau',
  _generic = '_generic',
}

export interface GdprConsent {
  necessary: boolean
  preferences: boolean
  marketing: boolean
  statistics: boolean
  granular?: { [key in GranularConsent]?: boolean | string }
}
