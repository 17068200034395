import type { Block, BlockAttribute, Embed, GdprConsent, Post } from '../types'
import { GranularConsent } from '../types'

interface ExtWindow extends Window {
  instgrm: {
    Embeds: {
      process: () => void
    }
  }

  InfogramEmbeds: {
    process: () => void
  }

  iframely: {
    load: () => void
  }

  twttr: {
    widgets: {
      load: () => void
    }
  }

  pym: {
    Parent: PymParent
  }

  FB: {
    XFBML: {
      parse: () => void
    }
  }

  symptoma_mode: string

  symptoma_integration: string

  tv1hlsplayer: {
    TV1HlsPlayer: () => void
  }

  YT: any

  oonVideo: (selector: string) => void
}

interface PymParent {
  // eslint-disable-next-line
  new (id: string, source: string, data: {}): void
}
declare let window: ExtWindow

function createPlayerForPublishers(video: HTMLElement) {
  let playerVars: {
    autoplay?: number
    mute?: number
    start?: number
  } = {}
  if (video.dataset.autoplay === 'true') {
    playerVars = {
      autoplay: 1,
      mute: 1,
    }
  }
  if (video.dataset.startAt) {
    const startAt = Number.parseInt(video.dataset.startAt)

    if (startAt > 0) {
      playerVars.start = startAt
    }
  }

  const playerOptions = {
    youtubeOptions: {
      videoId: video.dataset.videoId,
      playerVars,
    },
  }

  new window.YT.createPlayerForPublishers(
    `pfp-player-${video.dataset.videoId}`,
    () => {},
    playerOptions,
  )
  video.dataset.isInitialized = 'true'
}

/**
 * Embed Types
 */

class InstagramEmbed implements Embed {
  block = ['core-embed/instagram']
  script = 'https://www.instagram.com/embed.js'
  id = 'instagram'
  onSuccess = () => {
    if (window.instgrm && window.instgrm.Embeds) {
      window.setTimeout(() => {
        window.instgrm.Embeds.process()
      }, 500)
    }
  }

  provider = GranularConsent.instagram
}

class PlayerEmbed implements Embed {
  block = ['core/html', 'russmedia/tb-liveplayer']

  match(block: Block | undefined) {
    return block?.h?.includes('tv1hlsplayer') || false
  }

  script
    = 'https://player.cdn.tv1.eu/statics/tplayer/latest/production/lib/tv1hlsplayer.js'

  id = 'player'

  onSuccess = (block: Block | undefined) => {
    const matches = block?.h?.match(
      /<script\b[^>]*>([\s\S]*?)<\/script\b[^>]*>/,
    )

    if (matches && matches.length > 0 && window.tv1hlsplayer) {
      const code = matches[1]
      if (code) {
        window.setTimeout(() => {
          // eslint-disable-next-line no-eval
          eval(code)
        }, 500)
      }
    }
  }

  provider = GranularConsent.player
}

class InstagramEmbedHtml extends InstagramEmbed {
  block = ['core/html']
  match(block: Block | undefined) {
    return block?.h?.includes('//www.instagram.com/embed.js') || false
  }
}

class InfogramEmbed implements Embed {
  block = ['core/html']
  match(block: Block | undefined) {
    return (
      block?.h?.includes('infogram.com/js/dist/embed-loader-min.js') || false
    )
  }

  script = 'https://infogram.com/js/dist/embed-loader-min.js'
  id = 'infogram-async'
  onSuccess = () => {
    if (window.InfogramEmbeds && window.InfogramEmbeds.process) {
      window.setTimeout(() => {
        window.InfogramEmbeds.process()
      }, 500)
    }
  }

  provider = GranularConsent.infogram
}

class IframelyEmbed implements Embed {
  block = ['core/html']
  match(block: Block | undefined) {
    return (
      block?.h?.includes('//cdn.iframe.ly/embed.js')
      || block?.h?.includes('//iframely.net/embed.js')
      || false
    )
  }

  id = 'iframely-async'

  script = 'https://cdn.iframe.ly/embed.js'
  provider = GranularConsent.iframely
  onSuccess = () => {
    if (window.iframely && window.iframely.load) {
      window.setTimeout(() => {
        window.iframely.load()
      }, 500)
    }
  }
}

class FacebookEmbed implements Embed {
  block = ['core-embed/facebook']
  match(block: Block | undefined) {
    return block?.h?.includes(`//connect.facebook.net/de_DE/sdk.js`) || false
  }

  script = 'https://connect.facebook.net/de_DE/sdk.js#xfbml=1&version=v7.0'
  id = 'facebook'
  onSuccess = () => {
    if (window.FB && window.FB.XFBML) {
      window.FB.XFBML.parse()
    }
  }

  provider = GranularConsent.facebook
}

class FacebookHTMLEmbed implements Embed {
  block = ['core/html']
  id = 'facebook'
  match(block: Block | undefined) {
    return block?.h?.includes(`//www.facebook.com/plugins/post.php`) || false
  }

  provider = GranularConsent.facebook
}

class TwitterEmbed implements Embed {
  block = ['core/html', 'core-embed/twitter', 'core/embed']
  match = (block: Block | undefined) =>
    block?.h?.includes('//platform.twitter.com/widgets.js') || false

  script = 'https://platform.twitter.com/widgets.js'
  id = 'twitter'
  onSuccess = () => {
    if (window.twttr && window.twttr.widgets) {
      window.twttr.widgets.load()
    }
  }

  provider = GranularConsent.twitter
}

class AddendumEmbed implements Embed {
  block = ['core/html']
  match = (block: Block | undefined) =>
    block?.h?.includes('//data.addendum.org') || false

  script = 'https://data.addendum.org/gfx/oligrapher/pym.v1.min.js'
  id = 'addendum'
  onSuccess = (block: Block | undefined) => {
    const pymData = block?.h?.match(/new pym.Parent\((.*)\)/)
    if (pymData && pymData.length > 0) {
      window.setTimeout(() => {
        const [id, source] = pymData[1].split(', ')
        new window.pym.Parent( // eslint-disable-line no-new
          id.replace(/'/g, ''),
          source.replace(/'/g, ''),
          {},
        )
      }, 1000)
    }
  }

  provider = GranularConsent._generic
}

class OrfOnDemandEmbed implements Embed {
  block = ['core/html']
  match = (block: Block | undefined) =>
    block?.h?.includes('//orf.at/oon/media/4.5/oon.media-bundle.js') || false

  script = 'https://orf.at/oon/media/4.5/oon.media-bundle.js'
  id = 'oon-media'
  onSuccess = () => {
    if (window.oonVideo) {
      setTimeout(() => {
        window.oonVideo('.my-video-element')
      }, 1000)
    }
  }

  provider = GranularConsent._generic
}

class PodigeeEmbed implements Embed {
  block = ['core/html']
  id = 'podigee'
  match = (block: Block | undefined) =>
    block?.h?.includes(
      '//cdn.podigee.com/podcast-player/javascripts/podigee-podcast-player.js',
    )
    || block?.h?.includes(
      'https://player.podigee-cdn.net/podcast-player/javascripts/podigee-podcast-player.js',
    )
    || false

  onSuccess = (block: Block | undefined) => {
    const podcast = block?.h?.match(/data-configuration="?([^"> ]*)"?/)
    if (!podcast || podcast.length === 0) {
      return
    }
    const domElement = document.querySelector(`script.podigee-podcast-player`)
    if (domElement !== null) {
      const newElement = document.createElement('iframe')
      newElement.src = `${podcast[1]}&amp=true`
      newElement.height = '150px'
      newElement.width = '100%'
      newElement.className = 'podigee-podcast-player'

      domElement.parentElement?.replaceChild(newElement, domElement)
    }
  }

  provider = GranularConsent.pdg
}

class ApaGraphicsEmbed implements Embed {
  block = ['core/html']
  id = 'apa-graphics'
  match = (block: Block | undefined) =>
    block?.h?.includes('//grafik.apa.at/fastbuild') || false

  onSuccess = (block: Block | undefined) => {
    const matches = block?.h?.match(/<script>(.*)<\/script>/)
    if (matches && matches.length > 0) {
      const code = matches[1]
      if (code) {
        // eslint-disable-next-line no-eval
        eval(code)
      }
    }
  }

  provider = GranularConsent.apavs
}

class VplusOfferEmbed implements Embed {
  block = ['core/block']
  match = (block: Block | undefined) =>
    block?.h?.includes('wp-block-vplus-offers') || false

  onSuccess = (block: Block | undefined) => {
    const matches = block?.h?.match(/<script>([\s\S]*?)<\/script>/i)
    if (matches && matches.length > 0) {
      const code = matches[1]
      if (code) {
        setTimeout(() => {
        // eslint-disable-next-line no-eval
          eval(code)
        }, 500)
      }
    }
  }

  provider = GranularConsent._generic
}

class ApaEmbed implements Embed {
  block = ['core/html', 'custom/iframe']
  id = 'apa'
  match = (block: Block | undefined) =>
    hasKeyValueAttribute(block, 'url', 'uvp.apa.at')
    || block?.h?.includes('uvp.apa.at')
    || false

  provider = GranularConsent.apa
}

class KavedoEmbed implements Embed {
  block = ['core/html']
  match = (block: Block | undefined) =>
    block?.h?.includes('kavedo_player_container') || false

  script = 'https://vn.kavedo.com/kavedo_liveplayer_3.1.js'
  id = 'kavedo'
  provider = GranularConsent._generic
}

class SymptomaEmbed implements Embed {
  block = ['core/html']
  match = (block: Block | undefined) =>
    block?.h?.includes('symptoma-integration-embed') || false

  setup = () => {
    window.symptoma_mode = 'CORONA'
    window.symptoma_integration = 'inline'
  }

  appendTo = () => document.getElementById('symptoma-integration-embed')

  script = 'https://www.symptoma.de/de/embed.js'
  id = 'symptoma'
  provider = GranularConsent._generic
}

class GenericEmbed implements Embed {
  block = ['core/html', 'core/embed', 'custom/iframe']
  id = 'generic'
  match = (block: Block | undefined) => {
    // Ignore these Embeds to not match with the GenericEmbed.
    const ignoredEmbeds = excludeEmbedSpecials.map((embedSpecial) => {
      // Check if embed to be ignored would match with current block.
      return { ...embedSpecial, blockMatched: embedSpecial.match(block) }
    })

    if (ignoredEmbeds.some(embedSpecial => embedSpecial.blockMatched)) {
      return false
    }

    return (
      block?.h?.includes('<iframe')
      || block?.h?.includes('<script')
      || block?.a?.find(i => i.key === 'url') !== undefined
      || false
    )
  }

  provider = GranularConsent._generic
}

class SoccerwatchEmbed implements Embed {
  block = ['core/html']
  id = 'aisw'
  match = (block: Block | undefined) =>
    block?.h?.includes('https://soccerwatch.tv/embed')
    || block?.h?.includes('https://aisw.tv/embed')
    || block?.h?.includes('https://staige.tv/embed')
    || false

  onSuccess = () => {
    const resizeSoccerwatchElement = () => {
      const videoElements = document.getElementsByClassName('sw-embed')
      Array.from(videoElements as HTMLCollectionOf<HTMLDivElement>).forEach(
        ve => (ve.style.height = `${(ve.scrollWidth / 16) * 9}px`),
      )
    }
    window.setTimeout(resizeSoccerwatchElement, 500)
    window.addEventListener('resize', resizeSoccerwatchElement)
  }

  provider = GranularConsent._generic
}

class SpotifyEmbed implements Embed {
  block = ['core/embed']
  match = (block: Block | undefined) =>
    block?.h?.includes('https://open.spotify.com') || false

  provider = GranularConsent.spotify
}

class YoutubeEmbed implements Embed {
  block = ['core/html', 'core/embed', 'custom/iframe']
  id = 'youtube'
  match = (block: Block | undefined) =>
    hasKeyValueAttribute(block, 'url', 'youtube.com')
    || block?.h?.includes('youtube.com')
    || false

  provider = GranularConsent.youtube
}

class YoutubePfpEmbed implements Embed {
  block = ['core/html', 'core/embed', 'custom/iframe', 'russmedia/youtube-pfp']
  id = 'youtube-pfp'
  script = 'https://www.youtube.com/iframe_api?ima=1'
  requirements = ['https://imasdk.googleapis.com/js/sdkloader/ima3.js']
  match = (block: Block | undefined) =>
    block?.h?.includes('youtube-pfp') || false

  onSuccess = () => {
    if (window.YT) {
      const videos = Array.from<HTMLDivElement>(
        document.querySelectorAll(`div[data-video-id]`),
      )

      videos.forEach((video) => {
        if (video.dataset.isInitialized === 'true') {
          return
        }

        if (window.YT.createPlayerForPublishers) {
          createPlayerForPublishers(video)
        }
        else {
          // render the video as iframe
          const ytiframe = document.createElement('iframe')
          ytiframe.src = `https://youtube.com/embed/${video.dataset.videoId}`
          ytiframe.classList.add('w-full', 'aspect-video')
          video.appendChild(ytiframe)
          video.closest('.content-block')?.classList.add('content-block--wide')
          video.closest('.content-block')?.classList.remove('content-block')
          video.dataset.isInitialized = 'true'
        }
      })
    }
  }

  provider = GranularConsent.youtube
}

class BrightcoveEmbed implements Embed {
  block = ['core/html', 'russmedia/brightcove']
  id = 'brightcove'
  match = (block: Block | undefined) =>
    block?.h?.includes('brightcove') || false

  provider = GranularConsent.brightcove
  script
    = 'https://players.brightcove.net/6292321027001/default_default/index.min.js'
}

class OldBrightcoveEmbed implements Embed {
  block = ['custom/brightcove']
  id = 'brightcove'
  provider = GranularConsent.brightcove
}

class OpinionStageEmbed implements Embed {
  block = ['core/html']
  id = 'opinionstage'
  match = (block: Block | undefined) =>
    block?.h?.includes('https://www.opinionstage.com') || false

  provider = GranularConsent.opinionstage
  onSuccess = (block: Block | undefined) => {
    const matches = block?.h?.match(
      /<script\b[^>]*>([\s\S]*?)<\/script\b[^>]*>/,
    )
    if (matches && matches.length > 0) {
      const code = matches[1]
      if (code) {
        // eslint-disable-next-line no-eval
        eval(code)
      }
    }
  }

  setup = () => {
    window.addEventListener(
      'message',
      (e) => {
        let t, i
        if (e.origin === 'https://www.opinionstage.com') {
          try {
            ;(t = JSON.parse(e.data)),
            (i = document.querySelectorAll(t.widgetIframe)[0])
          }
          catch (n) {
            ;(console.error || console.log)(
              '[Embeds][OpinionStage]',
              'failed parsing OpinionStage event data:',
              n,
            )
            return
          }
          t.name === 'opinionstage:v1:widget:height:change'
          && (i.height = t.height.current)
        }
      },
      !1,
    )
  }
}

class TickarooEmbed implements Embed {
  block = ['core/html', 'russmedia/tickaroo']
  id = 'tickaroo'
  match = (block: Block | undefined) => block?.h?.includes('tickaroo') || false
  provider = GranularConsent.tickaroo
  script = 'https://cdn.tickaroo.com/webng/embedjs/tik4.js'
}

class RussmediaEmbed implements Embed {
  block = ['core/html']
  match = (block: Block | undefined) =>
    block?.h?.includes('vn.at/embed-integration.js') || false

  script = '/embed-integration.js'
  id = 'rm-embed'
  provider = GranularConsent._generic
}

class FileInboxEmbed implements Embed {
  block = ['core/html']
  match(block: Block | undefined) {
    return block?.h?.includes('//fileinbox.com/embed.js') || false
  }

  id = 'fileinbox'

  script = 'https://fileinbox.com/embed.js'
  provider = GranularConsent.fileinbox
}

class DatawrapperEmbed implements Embed {
  block = ['core/html', 'core/embed']
  id = 'datawrapper'

  match(block: Block | undefined) {
    return block?.h?.includes('https://datawrapper.dwcdn.net') || false
  }

  setup = (block: Block | undefined) => {
    const matches = block?.h?.match(
      /<script\b[^>]*>([\s\S]*?)<\/script\b[^>]*>/,
    )

    if (matches && matches.length > 0) {
      const code = matches[1]
      if (code) {
        // eslint-disable-next-line no-eval
        eval(code)
      }
    }
  }

  provider = GranularConsent.datawrapper
}

class TikTokEmbed implements Embed {
  block = ['core/html', 'core/embed']
  id = 'tiktok'
  reload = true

  match(block: Block | undefined) {
    return block?.h?.includes('https://www.tiktok.com/embed.js') || false
  }

  script = 'https://www.tiktok.com/embed.js'
  provider = GranularConsent.tiktok
}

class RotBlauEmbed implements Embed {
  block = ['core/html', 'core/embed', 'russmedia/rotblau']
  id = 'rotblau'

  match(block: Block | undefined) {
    return (
      block?.h?.includes('https://cdn.rotblau.app/widgets/widgets.js') || false
    )
  }

  script = 'https://cdn.rotblau.app/widgets/widgets.js'
  provider = GranularConsent.rotblau
}

const embedSpecials = [
  new InstagramEmbed(),
  new InstagramEmbedHtml(),
  new FacebookEmbed(),
  new FacebookHTMLEmbed(),
  new InfogramEmbed(),
  new TwitterEmbed(),
  new AddendumEmbed(),
  new OrfOnDemandEmbed(),
  new OpinionStageEmbed(),
  new PodigeeEmbed(),
  new ApaGraphicsEmbed(),
  new VplusOfferEmbed(),
  new ApaEmbed(),
  new KavedoEmbed(),
  new SymptomaEmbed(),
  new SoccerwatchEmbed(),
  new SpotifyEmbed(),
  new IframelyEmbed(),
  new RussmediaEmbed(),
  new BrightcoveEmbed(),
  new OldBrightcoveEmbed(),
  new YoutubeEmbed(),
  new YoutubePfpEmbed(),
  new PlayerEmbed(),
  new FileInboxEmbed(),
  new DatawrapperEmbed(),
  new TikTokEmbed(),
  new GenericEmbed(), // This actually needs to stay at the bottom, as it is kinda catch-all!
]

const excludeEmbedSpecials = [new TickarooEmbed(), new RotBlauEmbed()]

function hasKeyValueAttribute(
  block: Block | undefined,
  key: string,
  value: string,
) {
  if (!block || !Array.isArray(block.a)) {
    return false
  }

  return block?.a?.some(
    (attr: BlockAttribute) => attr.key === key && attr?.value?.includes(value),
  )
}

function getSpecials(block: Block, includeIgnoredSpecials = false): Embed[] {
  const specials = includeIgnoredSpecials
    ? [...excludeEmbedSpecials, ...embedSpecials]
    : embedSpecials

  return specials
    .filter((es: Embed) => es.block.includes(block.ot || ''))
    .filter((es: Embed) => es.match === undefined || es.match(block))
    .map((es: Embed) => {
      return { ...es, matchedBlock: block }
    })
}

const defaultName = 'GdprBlock'

export function handleGdprForBlock(
  block: Block,
  gdprSettings: GdprConsent,
  gdprBlockName: string = defaultName,
): Block {
  const b = Object.assign({}, block)
  if (!gdprSettings.marketing) {
    let specials = getSpecials(b)
    if (specials.length > 0 && typeof gdprSettings.granular !== 'undefined') {
      if (gdprSettings.granular[specials[0].provider]) {
        specials = []
      }
    }
    if (specials.length > 0) {
      b.t = gdprBlockName
      b.provider = specials[0].provider
    }
  }
  return b
}

export function handleGdpr(
  post: Post,
  gdprSettings: GdprConsent,
  gdprBlockName: string = defaultName,
): void {
  if (!gdprSettings.marketing && post.blocks) {
    post.blocks.map((block: Block) => {
      let specials = getSpecials(block)
      if (specials.length > 0 && typeof gdprSettings.granular !== 'undefined') {
        if (gdprSettings.granular[specials[0].provider]) {
          specials = []
        }
      }

      if (specials.length > 0) {
        if (block.t !== gdprBlockName) {
          block.previousType = block.t
        }
        block.t = gdprBlockName
        block.provider = specials[0].provider
      }
      else {
        if (block.previousType) {
          block.t = block.previousType
        }
      }
    })
  }
  else if (post.blocks) {
    post.blocks.map((block: Block) => {
      if (block.previousType) {
        block.t = block.previousType
      }
    })
  }
}

export function handleEmbeds(
  post: Post,
  gdprBlockName: string = defaultName,
): void {
  const blocks = post.blocks.filter(block => block.t !== gdprBlockName)
  const requiredScripts: Embed[] = []
  if (blocks && blocks.length > 0) {
    blocks.forEach((block: Block) => {
      const specials = getSpecials(block, true)
      requiredScripts.push(...specials)
    })
  }

  requiredScripts.forEach((embed: Embed, index) => {
    if (
      embed.script
      && (!document.body.querySelector(`script#${embed.id}`) || embed.reload)
    ) {
      if (embed.setup) {
        embed.setup(embed.matchedBlock)
      }
      const s = document.createElement('script')
      s.onload = () => {
        if (embed.onSuccess) {
          embed.onSuccess(embed.matchedBlock)
        }
      }
      s.id = embed.id || `script${index}`

      if (embed.reload) {
        document
          .querySelectorAll(`script#${embed.id}`)
          .forEach(e => e.remove()) // cleanup
        const url = new URL(embed.script)
        url.searchParams.append('cachebuster', Date.now().toString())
        s.src = url.toString()
      }
      else {
        s.src = embed.script
      }

      s.async = true
      let appendTo = document.body
      if (embed.appendTo && embed.appendTo() !== null) {
        appendTo = embed.appendTo() || document.body
      }
      if (embed.requirements && embed.requirements.length > 0) {
        embed.requirements.forEach((req, i) => {
          const rs = document.createElement('script')
          rs.id = `req${i}_${embed.id || `script${index}`}`
          rs.src = req
          rs.async = true
          appendTo.appendChild(rs)
        })
      }

      appendTo.appendChild(s)
    }
    else {
      if (embed.setup) {
        embed.setup(embed.matchedBlock)
      }
      if (embed.onSuccess) {
        embed.onSuccess(embed.matchedBlock)
      }
    }
  })
}

export function getProviderDetails(provider: GranularConsent): { name: string, description: string, consent_text: string } {
  const providerDetails: { [key: string]: { name: string, description: string, consent_text: string } } = {
    apa: {
      name: 'APA Videos',
      description: 'Videos, die von der Videoplattform der "Austria Presse Agentur" (APA) bereitgestellt werden.',
      consent_text: 'Ja, ich möchte Videos der APA angezeigt bekommen.',
    },
    apalvblg: {
      name: 'APA Live Blog',
      description: 'Drittanbieter, über den Live Blogs der "Austria Presse Agentur" (APA) eingebettet sind.',
      consent_text: 'Ja, ich möchte APA Live Blogs angezeigt bekommen.',
    },
    apavs: {
      name: 'APA Livecenter',
      description: 'Liveticker, Datencenter und Graphiken zu Sportereignissen oder Wahlen, die von der "Austria Presse Agentur" (APA) bereitgestellt werden.',
      consent_text: 'Ja, ich möchte APA Livecenter angezeigt bekommen.',
    },
    brightcove: {
      name: 'Brightcove',
      description: 'Brightcove Videos.',
      consent_text: 'Ja, ich möchte Videos von Brightcove angezeigt bekommen.',
    },
    datawrapper: {
      name: 'Datawrapper',
      description: 'Infographiken und Darstellungen zu verschiedenen Themen, die über den Drittanbieter "Datawrapper" erstellt wurden.',
      consent_text: 'Ja, ich möchte Inhalte von Datawrapper angezeigt bekommen.',
    },
    dpavs: {
      name: 'DPA Livecenter',
      description: 'Liveticker, Datencenter und Graphiken zu Sportereignissen oder Wahlen, die von der "Deutschen Presse Agentur" (DPA) bereitgestellt werden.',
      consent_text: 'Ja, ich möchte DPA Livecenter angezeigt bekommen.',
    },
    elfs: {
      name: 'Elfsight',
      description: 'Verschiedene Widgets, wie z.B. Slider mit redaktionellem Inhalt, die vom Drittanbieter "Elfsight" auf unserer Website eingebunden sind.',
      consent_text: 'Ja, ich möchte Inhalte von Elfsight angezeigt bekommen.',
    },
    facebook: {
      name: 'Facebook',
      description: 'Facebook-Posts, die auf unserer Seite eingebettet sind.',
      consent_text: 'Ja, ich möchte Inhalte von Facebook angezeigt bekommen.',
    },
    fileinbox: {
      name: 'Fileinbox',
      description: '',
      consent_text: 'Ja, ich möchte Inhalte von Fileinbox angezeigt bekommen.',
    },
    glomex: {
      name: 'Glomex',
      description: 'Videos, die von der Videoplattform "Glomex" bereitgestellt werden.',
      consent_text: 'Ja, ich möchte Videos von Glomex angezeigt bekommen.',
    },
    iframely: {
      name: 'Iframely',
      description: 'Artikelempfehlungen und Artikelverlinkungen, die über den Drittanbieter "iFramely" redaktionell erstellt und in Artikeln eingefügt werden.',
      consent_text: 'Ja, ich möchte Inhalte von Iframely angezeigt bekommen.',
    },
    infogram: {
      name: 'Infogram',
      description: 'Infographiken und Darstellungen zu verschiedenen Themen, die über den Drittanbieter "Infogram" erstellt wurden.',
      consent_text: 'Ja, ich möchte Inhalte von Infogram angezeigt bekommen.',
    },
    instagram: {
      name: 'Instagram',
      description: 'Posts von Instagram, die auf dieser Website eingebettet sind.',
      consent_text: 'Ja, ich möchte Inhalte von Instagram angezeigt bekommen.',
    },
    lvblg: {
      name: 'Sourcefabric Live Blog',
      description: 'Drittanbieter, über den Live Blogs unserer eigenen Redaktion eingebettet sind.',
      consent_text: 'Ja, ich möchte Inhalte von Sourcefabric Live Blog angezeigt bekommen.',
    },
    opinionstage: {
      name: 'Opinion Stage',
      description: 'Umfragen und Quizze, die über den Drittanbieter "Opinion Stage" bereitgestellt werden.',
      consent_text: 'Ja, ich möchte Inhalte von Opinion Stage angezeigt bekommen.',
    },
    outbrain: {
      name: 'Outbrain',
      description: 'Artikelempfehlungen, die auf dem Inhalt des Artikels, in dem sie eingebunden sind, basieren und vom Drittanbieter "Outbrain" bereitgestellt werden.',
      consent_text: 'Ja, ich möchte Inhalte von Outbrain angezeigt bekommen.',
    },
    pdg: {
      name: 'Podigee',
      description: 'Podcasts, die über den Drittanbieter "Podigee" bereitgestellt werden.',
      consent_text: 'Ja, ich möchte Podcasts von Podigee angezeigt bekommen.',
    },
    pinpoll: {
      name: 'Pinpoll',
      description: 'Umfrage-Tool, welches vom Drittanbieter "Pinpoll" bereitgestellt und dessen Inhalte von unserer Redaktion gesteuert werden.',
      consent_text: 'Ja, ich möchte Inhalte von Pinpoll angezeigt bekommen.',
    },
    spotify: {
      name: 'Spotify',
      description: 'Podcasts und Musik, die über den Drittanbieter "Spotify" bereitgestellt werden.',
      consent_text: 'Ja, ich möchte Inhalte von Spotify angezeigt bekommen.',
    },
    strmp: {
      name: 'Storymap',
      description: 'Infographiken, wie z.B. Darstellungen von Zeitachsen.',
      consent_text: 'Ja, ich möchte Inhalte von Storymap angezeigt bekommen.',
    },
    tckr: {
      name: 'Tickaroo',
      description: 'Liveticker und Datencenter zu Sportereignissen, meist mit Bezug zu Vorarlberg und dem "Ländlekicker".',
      consent_text: 'Ja, ich möchte Inhalte von Tickaroo angezeigt bekommen.',
    },
    tickaroo: {
      name: 'Tickaroo',
      description: 'Liveticker und Datencenter zu Sportereignissen, meist mit Bezug zu Vorarlberg und dem "Ländlekicker".',
      consent_text: 'Ja, ich möchte Inhalte von Tickaroo angezeigt bekommen.',
    },
    tiktok: {
      name: 'TikTok',
      description: 'Videos, die von der Videoplattform "TikTok" bereitgestellt werden.',
      consent_text: 'Ja, ich möchte Videos von TikTok angezeigt bekommen.',
    },
    twitter: {
      name: 'Twitter',
      description: 'Posts (Tweets) von Twitter, die auf dieser Website einbettet sind.',
      consent_text: 'Ja, ich möchte Inhalte von Twitter angezeigt bekommen.',
    },
    vi: {
      name: 'Video Intelligence',
      description: 'Videoempfehlungen, die vom Drittanbieter "Video Intelligence" bereitgestellt werden.',
      consent_text: 'Ja, ich möchte Videos von Video Intelligence angezeigt bekommen.',
    },
    vimeo: {
      name: 'Vimeo',
      description: 'Videos, die von der Videoplattform "Vimeo" bereitgestellt werden.',
      consent_text: 'Ja, ich möchte Videos von Vimeo angezeigt bekommen.',
    },
    youtube: {
      name: 'Youtube',
      description: 'Videos, die von der Videoplattform "Youtube" bereitgestellt werden.',
      consent_text: 'Ja, ich möchte Videos von Youtube angezeigt bekommen.',
    },
    _generic: {
      name: 'Sonstige',
      description: 'Sonstiger redaktioneller Inhalt, der über Drittanbieter zur Verfügung gestellt wird.',
      consent_text: 'Ja, ich möchte sonstige externe Inhalte angezeigt bekommen.',
    },
  }

  return providerDetails[provider] || providerDetails._generic
}
